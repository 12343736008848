<template>
  <v-container fluid>
    <search-form @submit="handleSearchFormSubmit"/>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="equipments"
      class="mt-1"
      item-key="id"
      show-select
      single-select
      hide-default-footer
    >
      <template
        v-slot:item.type="{ item }"
      >
        {{ item.equipmentTemp.type }}
      </template>

      <template
        v-slot:item.name="{ item }"
      >
        {{ item.templateName }}
      </template>

      <template
        v-slot:item.category="{ item }"
      >
        <span>
          {{ $vuetify.lang.t(`$vuetify.equipmentCategory.${ snakeToCamel(item.templateCategory.toLowerCase())}`) }}
        </span>

      </template>


    </v-data-table>
    <v-pagination
      v-model="queryParams.params.page"
      :length="queryParams.params.pageCount"
    />
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  import bus from '@/services/bus-event';
  import { snakeToCamel } from '@/utils';

  export default {
    components: {
      SearchForm: () => import('./SearchForm')
    },
    data () {
      return {
        selected: [],
        dialogEquipmentList: false,
        queryParams: {
          params: {
            page: 1,
            pageCount: 1
          }
        }
      }
    },
    computed: {
      ...mapGetters({
        equipments: 'equipment/equipments'
      }),
      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.equipment.deviceId'),
            align: 'start',
            sortable: false,
            value: 'deviceId',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipmentTemplate.category'),
            align: 'start',
            sortable: false,
            value: 'category',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipmentTemplate.name'),
            align: 'start',
            sortable: false,
            value: 'name',
          }
        ]
      }
    },

    watch: {
      'queryParams.params.page': {
        handler: function(newValue) {
          let params = Object.assign({}, this.queryParams.params)
          params.page = newValue - 1
          this.queryAllAvailablePage({params: params, data: this.queryParams.data }).then( (res) => {
            this.queryParams.params.pageCount = res.totalPages
          })
        }
      },

      selected: {
        handler: function(newValue) {
          bus.emit('equipment-change', newValue)
        }
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      ...mapActions ({
        queryAllAvailablePage: 'equipment/queryAllAvailablePage'
      }),

      snakeToCamel,

      initialize() {
        let params = Object.assign({}, this.queryParams.params)
        params.page--
        this.queryAllAvailablePage({params: params, data: this.queryParams.data }).then( (res) => {
          this.queryParams.params.pageCount = res.totalPages
        })
      },

      handleDistribute  () {
        this.dialogEquipmentList = true
      },

      handleSearchFormSubmit(data) {
        this.queryParams.data = data
        let params = Object.assign({}, this.queryParams.params)
        params.page = 0
        this.queryAllAvailablePage({params: params, data }).then( (res) => {
          this.queryParams.params.pageCount = res.totalPages
        })
      }
    }
  }
</script>
